import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import {
  spotifyByArtists,
  geniusByCredits,
  complementDiscogs,
  batchDiscogs,
  setPerformer,
  loadNorming,
} from "Redux/norming/actions";
// -------------------------------------------------------------
import Number from "Components/Number";
import AdvancedProgress from "Components/AdvancedProgress";
import NormingButtons from "../Shared/NormingButtons";
import EditableTag from "../Shared/EditableTag";
import Research from "./Research";
import { stem, capitalize } from "Aux";
import {
  retrieveAM,
  retrieveSonar,
  retrieveGenius,
  querySongs,
} from "Redux/songs/actions";
// -------------------------------------------------------------
import "./css.css";
// -------------------------------------------------------------
// -------------------------------------------------------------
const datatype = "performer";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [applemusic, setApplemusic] = useState(false);
  const [genius, setGenius] = useState(false);
  const [sonar, setSonar] = useState(false);
  const [reviewing, setReviewing] = useState(null);
  const [researchTitle, setResearchTitle] = useState(null);
  const norming = useSelector((state) => state.norming);
  const { project } = useSelector((state) => state.projects);

  const { data, predictions, unsynced } = norming[datatype];

  console.log("Predictions are", predictions);

  useEffect(() => {
    dispatch({ type: "SET_ARTISTS" });
    dispatch(loadNorming(datatype));
  }, []);

  const artists = project?.keys?.map((artist) => artist.pivot) || [];
  const geniusIds = project?.artists?.map((artist) => artist.id) || [];

  const people = project?.keys?.map((key) => key.pivot) || [];
  const geniuses = project?.artists || [];

  const [activeService, setActiveService] = useState("apple");

  return (
    <div>
      {researchTitle && (
        <Research
          researchTitle={researchTitle}
          people={people}
          geniusIds={geniusIds}
          onCloseResearch={() => setResearchTitle(null)}
        />
      )}
      <div className="field is-grouped is-grouped-multiline">
        {people.map((key, keyindex) => {
          return (
            <div key={`key-${keyindex}`} className="control">
              <div className="tags has-addons">
                <div className="tag is-info is-light">{key}</div>
              </div>
            </div>
          );
        })}
        {geniuses.map((artist, artistindex) => {
          return (
            <div className="control" key={`artist-${artistindex}`}>
              <div className="tags has-addons">
                <div className="tag is-info is-light">{artist.artist}</div>
                <div className="tag is-warning is-light">{artist.id}</div>
              </div>
            </div>
          );
        })}
      </div>
      <AdvancedProgress />
      <NormingButtons
        size={data.length}
        unsynced={unsynced}
        datatype={datatype}
      >
        <td>&nbsp;</td>
        <td>
          <div className="buttons has-addons">
            <div
              className={`button is-small ${applemusic ? "is-loading" : ""}`}
              onClick={() => {
                setApplemusic(9);
                dispatch(
                  querySongs(
                    "applemusic",
                    artists,
                    geniusIds,
                    predictions,
                    () => {
                      setApplemusic(false);
                    }
                  )
                );
              }}
            >
              <span className="icon">
                <i className="fa-duotone fa-solid fa-music-magnifying-glass"></i>
              </span>
              <span>Query AppleMusic</span>
            </div>
            <div
              className={`button is-small ${sonar ? "is-loading" : ""}`}
              onClick={() => {
                setSonar(9);
                dispatch(
                  querySongs("sonar", artists, geniusIds, predictions, () => {
                    setSonar(false);
                  })
                );
              }}
            >
              <span className="icon">
                <i className="fa-duotone fa-solid fa-music-magnifying-glass"></i>
              </span>
              <span>Query Sonar</span>
            </div>
            <div
              className={`button is-small ${genius ? "is-loading" : ""}`}
              onClick={() => {
                setGenius(9);
                dispatch(
                  querySongs("genius", artists, geniusIds, predictions, () => {
                    setGenius(false);
                  })
                );
              }}
            >
              <span className="icon">
                <i className="fa-duotone fa-solid fa-music-magnifying-glass"></i>
              </span>
              <span>Query Genius</span>
            </div>
          </div>
        </td>
      </NormingButtons>

      <MainTable
        norming={norming}
        setReviewing={setReviewing}
        datatype={datatype}
        setResearchTitle={setResearchTitle}
      />
    </div>
  );
};
export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({ norming, setReviewing, datatype, setResearchTitle }) => {
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.projects);

  const [activeTab, setActiveTab] = useState("royalty-down");
  const { data, predictions } = norming[datatype];
  if (!data) return null;
  if (!data.length) return null;
  const person = project.keys[0].pivot;

  const splitComposers = (str) => {
    str = str.replace(/[,&\(\)]/g, "-");
    return str.split("-");
  };

  let totalCount = 0;
  for (var row of data) {
    totalCount += row["Count"];
  }
  let totalRoyalties = 0;
  if (data[0].hasOwnProperty("Total_Royalty")) {
    for (var row of data) {
      if (row.hasOwnProperty("Total_Royalty")) {
        totalRoyalties += row["Total_Royalty"];
      }
    }
  }

  const percent = (x, total) => {
    return x / total;
  };

  const setSong = (rowindex, value) => {
    return setPerformer(rowindex, "Song_Name_9LC", value);
  };
  const setArtist = (rowindex, value) => {
    return setPerformer(rowindex, "Release_Artist_9LC", value);
  };
  const setRelease = (rowindex, value) => {
    return setPerformer(rowindex, "Release_Date_9LC", value);
  };
  const setFeatured = (rowindex, value) => {
    return setPerformer(rowindex, "Featured_Artist_9LC", value);
  };

  let sortFunc;
  switch (activeTab) {
    case "title-up":
      sortFunc = (a, b) =>
        a["Consensus_Title_SB"].localeCompare(b["Consensus_Title_SB"]);
      break;
    case "title-down":
      sortFunc = (a, b) =>
        b["Consensus_Title_SB"].localeCompare(a["Consensus_Title_SB"]);
      break;
    case "count-up":
      sortFunc = (a, b) => a["Count"] - b["Count"];
      break;
    case "count-down":
      sortFunc = (a, b) => b["Count"] - a["Count"];
      break;
    case "royalty-up":
      sortFunc = (a, b) => a["Total_Royalty"] - b["Total_Royalty"];
      break;
    case "royalty-down":
      sortFunc = (a, b) => b["Total_Royalty"] - a["Total_Royalty"];
      break;
  }

  const sortedIndices = data.map((row, index) => index);
  sortedIndices.sort((a, b) => sortFunc(data[a], data[b]));

  const sortedData = sortedIndices.map((index) => data[index]);
  const sortedPredictions = sortedIndices.map((index) => predictions[index]);

  return (
    <div className="table-wrapper">
      <table className="table is-striped_ is-bordered is-fullwidth">
        <thead
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 10,
            boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
          }}
        >
          <tr>
            <th>
              <div className="tabs is-small is-toggle is-toggle-rounded">
                <ul>
                  <li
                    className={activeTab.startsWith("title") ? "is-active" : ""}
                  >
                    <a
                      onClick={() =>
                        setActiveTab(
                          activeTab === "title-down" ? "title-up" : "title-down"
                        )
                      }
                    >
                      <span>Title</span>
                      {activeTab.startsWith("title") && (
                        <span className="icon is-small">
                          <i
                            className={`fas fa-chevron-${
                              activeTab === "title-up" ? "up" : "down"
                            }`}
                          ></i>
                        </span>
                      )}
                    </a>
                  </li>
                  <li
                    className={activeTab.startsWith("count") ? "is-active" : ""}
                  >
                    <a
                      onClick={() =>
                        setActiveTab(
                          activeTab === "count-down" ? "count-up" : "count-down"
                        )
                      }
                    >
                      <span>Count</span>
                      {activeTab.startsWith("count") && (
                        <span className="icon is-small">
                          <i
                            className={`fas fa-chevron-${
                              activeTab === "count-up" ? "up" : "down"
                            }`}
                          ></i>
                        </span>
                      )}
                    </a>
                  </li>
                  <li
                    className={
                      activeTab.startsWith("royalty") ? "is-active" : ""
                    }
                  >
                    <a
                      onClick={() =>
                        setActiveTab(
                          activeTab === "royalty-down"
                            ? "royalty-up"
                            : "royalty-down"
                        )
                      }
                    >
                      <span>Royalty</span>
                      {activeTab.startsWith("royalty") && (
                        <span className="icon is-small">
                          <i
                            className={`fas fa-chevron-${
                              activeTab === "royalty-up" ? "up" : "down"
                            }`}
                          ></i>
                        </span>
                      )}
                    </a>
                  </li>
                </ul>
              </div>
            </th>
            <th>Normed</th>
            <th>Status</th>
            <th>Predicted</th>
            <th>Apple</th>
            <th>Sonar</th>
            <th>Genius</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, rowindex) => {
            const item = `${row["Consensus_Title_SB"].toUpperCase()}`;
            //const item = `${person.toUpperCase()} ${row[
            //  "Consensus_Title_SB"
            //].toUpperCase()}`;

            const consensus = stem(row["Consensus_Title_SB"]);
            const song = stem(row["Song_Name_9LC"]);
            const isWarning = song !== "" && consensus !== song;

            const collectedMatches = [];
            if (sortedPredictions[rowindex]?.applemusic?.length) {
              collectedMatches.push(sortedPredictions[rowindex]?.applemusic);
            }
            if (sortedPredictions[rowindex]?.sonar?.length) {
              collectedMatches.push(sortedPredictions[rowindex]?.sonar);
            }
            if (sortedPredictions[rowindex]?.genius?.length) {
              collectedMatches.push(sortedPredictions[rowindex]?.genius);
            }

            const matches = [...new Set(collectedMatches)];
            // pick out consensus: the match with the earliest release date
            const consensusMatch = matches.reduce((earliest, current) => {
              return new Date(earliest.release_date) >
                new Date(current.release_date)
                ? current // if current is earlier, keep it
                : earliest; // if earliest is earlier (or equal), keep it
            }, matches[0]);

            const collectedTitles = matches.map((match) => match[2]);
            const collectedPerformers = matches.map((match) => match[0]);
            const collectedPerformersSet = [...new Set(collectedPerformers)];

            let status = "";
            if (collectedPerformers.length > 0) {
              status =
                collectedPerformers.length > 1
                  ? collectedPerformersSet.length > 1
                    ? `Discrepancy (${collectedPerformers.length} / ${collectedPerformersSet.length})`
                    : `Agreement (${collectedPerformers.length})`
                  : "Single Match";
            }

            let statusClass = null;
            let statusIcon = null;
            switch (status.replace(/ \(.*\)/, "")) {
              case "Discrepancy":
                statusClass = "is-danger";
                statusIcon = "fa-duotone fa-solid fa-triangle-exclamation";
                break;
              case "Agreement":
                statusClass = "is-success";
                statusIcon = "fa-duotone fa-solid fa-check";
                break;
              case "Single Match":
                statusClass = "";
                statusIcon = "fa-duotone fa-solid fa-check";
                break;
            }

            let title = consensusMatch?.[2];
            let artist = consensusMatch?.[0];
            let release = consensusMatch?.[1];
            let featured =
              consensusMatch?.length > 4 ? consensusMatch?.[3] : "";

            const sortedRowindex = sortedIndices[rowindex];

            return (
              <tr key={`row-${rowindex}`}>
                <td>
                  <div className="tags has-addons bottom-zero">
                    <div className="tag">{rowindex + 1}</div>
                    <div
                      className="tag is-info is-light is-clickable"
                      onClick={() => {
                        const value = capitalize(row["Consensus_Title_SB"]);
                        dispatch(
                          setPerformer(sortedRowindex, "Song_Name_9LC", value)
                        );
                      }}
                    >
                      {row["Consensus_Title_SB"]}
                    </div>
                  </div>
                  <small>
                    Count: <Number value={row["Count"]} isRounded={9} /> (
                    <Number
                      value={percent(row["Count"], totalCount)}
                      isPercentage={9}
                    />
                    )
                    <br />
                    {row.hasOwnProperty("Total_Royalty") && (
                      <div>
                        Nominal:{" "}
                        <Number value={row["Total_Royalty"]} isRounded={9} /> (
                        <Number
                          value={percent(row["Total_Royalty"], totalRoyalties)}
                          isPercentage={9}
                        />
                        )
                      </div>
                    )}
                  </small>
                  <button
                    className="button is-small"
                    onClick={() => {
                      setResearchTitle(row["Consensus_Title_SB"]);
                    }}
                  >
                    <span className="icon">
                      <i className="fa-duotone fa-solid fa-music-magnifying-glass"></i>
                    </span>
                    <span>Research</span>
                  </button>
                </td>
                <td>
                  <EditableTag
                    currentValue={row["Song_Name_9LC"]}
                    rowindex={sortedRowindex}
                    className="tag is-warning is-large click"
                    updateData={setSong}
                  />
                  <EditableTag
                    currentValue={row["Release_Artist_9LC"]}
                    rowindex={sortedRowindex}
                    className="tag is-primary is-large click"
                    updateData={setArtist}
                  />
                  <EditableTag
                    currentValue={row["Release_Date_9LC"]}
                    rowindex={sortedRowindex}
                    className="tag is-info is-large click"
                    updateData={setRelease}
                  />
                  <EditableTag
                    currentValue={row["Featured_Artist_9LC"]}
                    rowindex={sortedRowindex}
                    className="tag is-success is-large click"
                    updateData={setFeatured}
                  />
                </td>
                <td>
                  {status && (
                    <div
                      className={`tag is-light is-large ${statusClass}`}
                      style={{ height: "100px" }}
                    >
                      <span className="icon">
                        <i className={statusIcon} />
                      </span>
                    </div>
                  )}
                </td>
                <td>
                  {title && artist && release && (
                    <div
                      className="box click-box"
                      onClick={() => {
                        dispatch(
                          setPerformer(sortedRowindex, "Song_Name_9LC", title)
                        );
                        dispatch(
                          setPerformer(
                            sortedRowindex,
                            "Release_Artist_9LC",
                            artist
                          )
                        );
                        dispatch(
                          setPerformer(
                            sortedRowindex,
                            "Release_Date_9LC",
                            release
                          )
                        );
                        dispatch(
                          setPerformer(
                            sortedRowindex,
                            "Featured_Artist_9LC",
                            featured
                          )
                        );
                      }}
                    >
                      <div className="tag is-warning">{title}</div>
                      <div className="tag is-primary">{artist}</div>
                      <div className="tag is-info">{release}</div>
                      <div className="tag is-success">{featured}</div>
                    </div>
                  )}
                </td>
                <td>
                  {sortedPredictions[rowindex] &&
                    sortedPredictions[rowindex] != -1 && (
                      <div
                        className="box click-box"
                        onClick={() => {
                          dispatch(
                            setPerformer(
                              sortedRowindex,
                              "Song_Name_9LC",
                              sortedPredictions[rowindex]["applemusic"][2]
                            )
                          );
                          dispatch(
                            setPerformer(
                              sortedRowindex,
                              "Release_Artist_9LC",
                              sortedPredictions[rowindex]["applemusic"][0]
                            )
                          );
                          dispatch(
                            setPerformer(
                              sortedRowindex,
                              "Release_Date_9LC",
                              sortedPredictions[rowindex]["applemusic"][1]
                            )
                          );
                          dispatch(
                            setPerformer(
                              sortedRowindex,
                              "Featured_Artist_9LC",
                              sortedPredictions[rowindex]["applemusic"][3]
                            )
                          );
                        }}
                      >
                        <div className="tag is-warning is-light">
                          {sortedPredictions[rowindex]["applemusic"][2]}
                        </div>
                        <div className="tag is-primary is-light">
                          {sortedPredictions[rowindex]["applemusic"][0]}
                        </div>
                        <div className="tag is-info is-light">
                          {sortedPredictions[rowindex]["applemusic"][1]}
                        </div>
                        <div className="tag is-success is-light">
                          {sortedPredictions[rowindex]["applemusic"].length > 4
                            ? sortedPredictions[rowindex]["applemusic"][3]
                            : ""}
                        </div>
                      </div>
                    )}
                </td>
                <td>
                  {sortedPredictions[rowindex] &&
                    sortedPredictions[rowindex] != -1 && (
                      <div
                        className="box click-box"
                        onClick={() => {
                          dispatch(
                            setPerformer(
                              sortedRowindex,
                              "Song_Name_9LC",
                              sortedPredictions[rowindex]["sonar"][2]
                            )
                          );
                          dispatch(
                            setPerformer(
                              sortedRowindex,
                              "Release_Artist_9LC",
                              sortedPredictions[rowindex]["sonar"][0]
                            )
                          );
                          dispatch(
                            setPerformer(
                              sortedRowindex,
                              "Release_Date_9LC",
                              sortedPredictions[rowindex]["sonar"][1]
                            )
                          );
                          dispatch(
                            setPerformer(
                              sortedRowindex,
                              "Featured_Artist_9LC",
                              sortedPredictions[rowindex]["sonar"][3]
                            )
                          );
                        }}
                      >
                        <div className="tag is-warning is-light">
                          {sortedPredictions[rowindex]["sonar"][2]}
                        </div>
                        <div className="tag is-primary is-light">
                          {sortedPredictions[rowindex]["sonar"][0]}
                        </div>
                        <div className="tag is-info is-light">
                          {sortedPredictions[rowindex]["sonar"][1]}
                        </div>
                        <div className="tag is-success is-light">
                          {sortedPredictions[rowindex]["sonar"].length > 4
                            ? sortedPredictions[rowindex]["sonar"][3]
                            : ""}
                        </div>
                      </div>
                    )}
                </td>
                <td>
                  {sortedPredictions[rowindex] &&
                    sortedPredictions[rowindex] != -1 && (
                      <div
                        className="box click-box"
                        onClick={() => {
                          dispatch(
                            setPerformer(
                              sortedRowindex,
                              "Song_Name_9LC",
                              sortedPredictions[rowindex]["genius"][2]
                            )
                          );
                          dispatch(
                            setPerformer(
                              sortedRowindex,
                              "Release_Artist_9LC",
                              sortedPredictions[rowindex]["genius"][0]
                            )
                          );
                          dispatch(
                            setPerformer(
                              sortedRowindex,
                              "Release_Date_9LC",
                              sortedPredictions[rowindex]["genius"][1]
                            )
                          );
                          dispatch(
                            setPerformer(
                              sortedRowindex,
                              "Featured_Artist_9LC",
                              sortedPredictions[rowindex]["genius"][3]
                            )
                          );
                        }}
                      >
                        <div className="tag is-warning is-light">
                          {sortedPredictions[rowindex]["genius"][2]}
                        </div>
                        <div className="tag is-primary is-light">
                          {sortedPredictions[rowindex]["genius"][0]}
                        </div>
                        <div className="tag is-info is-light">
                          {sortedPredictions[rowindex]["genius"][1]}
                        </div>
                        <div className="tag is-success is-light">
                          {sortedPredictions[rowindex]["genius"].length > 4
                            ? sortedPredictions[rowindex]["genius"][3]
                            : ""}
                        </div>
                      </div>
                    )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const GeniusBox = ({ row, predictions, rowindex, person, role }) => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState("init");
  const [results, setResults] = useState([]);

  switch (mode) {
    case "init":
      return (
        <div className="box">
          <div>
            <button
              className="button is-small"
              onClick={() => {
                setMode("norming");
                dispatch(
                  retrieveGenius(row, (results) => {
                    console.log("Results are", results);
                    setResults(results);
                    setMode("results");
                  })
                );
              }}
            >
              <span className="icon">
                <i className="fa-duotone fa-solid fa-music-magnifying-glass"></i>
              </span>
              <span>Research this song</span>
            </button>
          </div>
        </div>
      );
    case "norming":
      return (
        <div
          className="box"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <i
            className="fa-duotone fa-solid fa-music-magnifying-glass fa-beat-fade"
            style={{ fontSize: "2em" }}
          ></i>
        </div>
      );
    case "results":
      if (!results || !results.length)
        return (
          <div className="box">
            <div className="tag is-danger is-light">No results found</div>
          </div>
        );
      return (
        <div className="box">
          <table className="table is-striped">
            <tbody>
              {results.map((entry, index) => {
                return (
                  <tr key={`${entry.id}-${index}`}>
                    <td>{entry.genius_id}</td>
                    <td>{entry.artist}</td>
                    <td>
                      <div className="tag is-primary is-light">
                        {entry.track}
                      </div>
                    </td>
                    <td>
                      <div className="tag">{entry.release_date}</div>
                    </td>
                    <td>
                      {entry.featured_artists?.map((x, i) => {
                        return (
                          <div
                            key={`${x}-${i}`}
                            className="tag is-info is-light"
                          >
                            {x}
                          </div>
                        );
                      })}
                    </td>
                    <td>
                      {entry.producer_artists?.map((x, i) => {
                        return (
                          <div
                            key={`${x}-${i}`}
                            className="tag is-info is-light"
                          >
                            {x}
                          </div>
                        );
                      })}
                    </td>
                    <td>
                      {entry.writer_artists?.map((x, i) => {
                        return (
                          <div
                            key={`${x}-${i}`}
                            className="tag is-info is-light"
                          >
                            {x}
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
  }
};

const AppleBox = ({ row, predictions, rowindex, person, role }) => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState("init");
  const [results, setResults] = useState([]);

  switch (mode) {
    case "init":
      return (
        <div className="box">
          <div>
            <button
              className="button is-small"
              onClick={() => {
                setMode("norming");
                dispatch(
                  retrieveAM(row, (results) => {
                    console.log("Results are", results);
                    setResults(results);
                    setMode("results");
                  })
                );
              }}
            >
              <span className="icon">
                <i className="fa-duotone fa-solid fa-music-magnifying-glass"></i>
              </span>
              <span>Research this song</span>
            </button>
          </div>
        </div>
      );
    case "norming":
      return (
        <div
          className="box"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <i
            className="fa-duotone fa-solid fa-music-magnifying-glass fa-beat-fade"
            style={{ fontSize: "2em" }}
          ></i>
        </div>
      );
    case "results":
      if (!results)
        return (
          <div className="box">
            <div className="tag is-danger is-light">No results found</div>
          </div>
        );
      return (
        <div className="box">
          <table className="table is-striped">
            <tbody>
              {results.map((entry, index) => {
                return (
                  <tr key={`${entry.id}-${index}`}>
                    <td>
                      <a href={entry.url} target="_blank">
                        {entry.id}
                      </a>
                    </td>
                    <td>
                      <div className="tag">{entry.release_date}</div>
                    </td>
                    <td>
                      <div className="tag is-primary is-light">
                        {entry.artist}
                      </div>
                    </td>
                    <td>
                      <div className="tag is-info is-light">{entry.title}</div>
                    </td>
                    <td>
                      {entry.writers?.map((writer, writerindex) => (
                        <div
                          key={`${writer}-${writerindex}`}
                          className="tag is-info is-light"
                        >
                          {writer}
                        </div>
                      ))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
  }
};

const SonarBox = ({ row, predictions, rowindex, person, role }) => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState("init");
  const [results, setResults] = useState([]);

  switch (mode) {
    case "init":
      return (
        <div className="box">
          <div>
            <button
              className="button is-small"
              onClick={() => {
                setMode("norming");
                dispatch(
                  retrieveSonar(row, person, role, (results) => {
                    console.log("Results are", results);
                    setResults(results);
                    setMode("results");
                  })
                );
              }}
            >
              <span className="icon">
                <i className="fa-duotone fa-solid fa-music-magnifying-glass"></i>
              </span>
              <span>Research this song</span>
            </button>
          </div>
        </div>
      );
    case "norming":
      return (
        <div
          className="box"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <i
            className="fa-duotone fa-solid fa-music-magnifying-glass fa-beat-fade"
            style={{ fontSize: "2em" }}
          ></i>
        </div>
      );
    case "results":
      return (
        <div className="box">
          <table className="table is-striped">
            <tbody>
              <tr>
                <td>Song</td>
                <td>
                  <div className="tag is-success is-light">
                    {results["title"]}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Performer</td>
                <td>
                  <div className="tag is-success is-light">
                    {results["performer"]}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Featured</td>
                <td>
                  <div className="tag is-success is-light">
                    {results["featured_artist"]}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Composers</td>
                <td>
                  <div className="tags">
                    {results["composers"]?.split(",")?.map((x, i) => {
                      return (
                        <div key={`${x}-${i}`} className="tag">
                          {x}
                        </div>
                      );
                    })}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Key Person Role</td>
                <td>
                  <div className="tag is-success is-light">
                    {results["key_person_role"]}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Explanation</td>
                <td>{results["explanation"]}</td>
              </tr>
              <tr>
                <td>Reference</td>
                <td>
                  <a href={results["reference_url"]} target="_blank">
                    {results["reference_url"]}
                  </a>
                </td>
              </tr>
              <tr></tr>
            </tbody>
          </table>
        </div>
      );
  }
};

const Stats = () => {
  const datatype = "performer";
  const norming = useSelector((state) => state.norming);
  const { data } = norming[datatype];
  if (!data) return null;
  if (!data.length) return null;
  const subset = data.filter((x) => x["Pool_Revenue_9LC"] !== "X");
  const value = subset.filter(
    (x) => x["Release_Artist_9LC"] !== "" && x["Release_Artist_9LC"] !== null
  ).length;
  const total = subset.length;
  return (
    <div className="progress-wrapper">
      <span>
        {value}&nbsp;of&nbsp;{total}
      </span>
      <progress max={total} value={value} className="progress is-primary" />
    </div>
  );
};

const SongItem__ = ({ row, type, onSubmit, isWarning }) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(row[type] || "");

  const tagColor = isWarning ? "is-warning" : "is-success is-light";
  const submit = () => {
    setEdit(false);
    onSubmit(value);
  };

  if (edit)
    return (
      <input
        value={value}
        onChange={(evt) => {
          setValue(evt.target.value);
        }}
        className="input"
        type="text"
        onBlur={submit}
        onKeyDown={(evt) => evt.key === "Enter" && submit()}
      />
    );
  return (
    <div
      onClick={() => setEdit(true)}
      className={`tag ${tagColor} is-clickable`}
    >
      {row[type]}
    </div>
  );
};
