import { useSelector } from "react-redux";
// ------------------------------------------------------
import "./css.css";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({}) => {
  const progress = useSelector((state) => state.advancedprogress);
  const { title, status, onClose, total, current } = progress;

  if (!progress.total) return null;

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card sum-attributes">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {" "}
            <i className="fa-duotone fa-solid fa-ring fa-spin" />
            &nbsp;&nbsp;&nbsp;
            {title}
          </p>
        </header>

        <section className="modal-card-body">
          <div className="box" style={{ minHeight: 160 }}>
            <progress
              className="progress is-primary"
              max={total}
              value={current}
            />
            <div>{status}</div>
          </div>
        </section>

        <footer className="modal-card-foot">
          {onClose && (
            <button className="button" onClick={onClose}>
              <span className="icon">
                <i className="fa-duotone fa-solid fa-xmark"></i>
              </span>
              <span>Stop</span>
            </button>
          )}
        </footer>
      </div>
    </div>
  );
};
export default Index;
