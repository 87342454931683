import finishedMp3 from "Media/finished.mp3";
import startingMp3 from "Media/start.mp3";
const finished = new Audio(finishedMp3);
const starting = new Audio(startingMp3);

// -------------------------------------------------------
// -------------------------------------------------------
export const initializeAdvancedProgress =
  (title, total, onClose = null) =>
  (dispatch) => {
    starting.play();
    const onCloseExtended = onClose
      ? () => {
          onClose();
          //dispatch(closeAdvancedProgress());
          dispatch({
            type: "INITIALIZE_ADVANCED_PROGRESS",
            title: "Please wait...",
            status: "Stopping query...",
            current: 9,
            total: 9,
          });
        }
      : null;
    dispatch({
      type: "INITIALIZE_ADVANCED_PROGRESS",
      title,
      total,
      onClose: onCloseExtended,
    });
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const updateAdvancedProgress = (current, status) => (dispatch) => {
  dispatch({ type: "UPDATE_ADVANCED_PROGRESS", current, status });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const closeAdvancedProgress = () => (dispatch) => {
  finished.play();
  dispatch({ type: "CLOSE_ADVANCED_PROGRESS" });
};
