const initialState = {
    numberPoints: 0,
};


// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  // -------------------------------------------------------
  switch (action.type) {
    // -------------------------------------------------------
    case "SET_NUMBER_SOURCE_TRAINING_POINTS":
      return {
        ...state,
        numberPoints: action.numberPoints,
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    default:
      return state;
  }
};

export default reducer;
