const Number = ({ value, column, factor, isRounded, isPercentage }) => {
    if(column === "Reference Date") return value;
    if(!value) return value;
    value = parseFloat(value);
    if(factor) value = value / factor;
    if(isRounded) value = Math.round(value);
    const Formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: value % 1 === 0 ? 0 : 2,
        maximumFractionDigits: 2
    });   
    if(isPercentage) return Formatter.format(100*value.toFixed(4)) + "%";
    const returnString = Formatter.format(value);
    if(returnString === "0.00") return "0";
    return returnString;
};

export default Number;