const initialTabs = [
  {
    tab: "frame",
    headline: "Frame",
    iconclass: "fa-duotone fa-microchip",
    active: true,
  },
  {
    tab: "qca",
    headline: "Initial QC",
    iconclass: "fa-duotone fa-check-to-slot",
  },
  {
    tab: "norming",
    headline: "Norming",
    iconclass: "fa-duotone fa-align-justify",
    tabs: [
      {
        tab: "songs",
        headline: "Songs",
        iconclass: "fa-duotone fa-music",
        active: true,
        tabs: [
          {
            tab: "normFilter",
            headline: "Filter Titles",
            iconclass: "fa-duotone fa-filter-list",
            active: true,
          },
          {
            tab: "normPool",
            headline: "Pool Rev",
            iconclass: "fa-duotone fa-swimming-pool",
          },
          {
            tab: "normSong",
            headline: "Grouping",
            iconclass: "fa-duotone fa-object-group",
          },
          {
            tab: "normPerformer",
            headline: "Performers",
            iconclass: "fa-duotone fa-microphone-stand",
          },
          {
            tab: "normMeta",
            headline: "Song Details",
            iconclass: "fa-duotone fa-table",
          },
          {
            tab: "normCredits",
            headline: "Credits",
            iconclass: "fa-duotone fa-list-music",
          },
        ],
      },

      {
        tab: "normSource",
        headline: "Sources",
        iconclass: "fa-duotone fa-code-branch",
        /*tabs: [
          {
            tab: "normSource",
            headline: "Tabular",
            iconclass: "fa-duotone fa-table-list",
            active: true,
          },
          {
            tab: "normSourceGroups",
            headline: "Groups",
            iconclass: "fa-duotone fa-object-group",
          },
        ],*/
      },
      {
        tab: "normIncome",
        headline: "Income Types",
        iconclass: "fa-duotone fa-money-check-alt",
      },
      {
        tab: "normCountry",
        headline: "Countries",
        iconclass: "fa-duotone fa-globe-americas",
      },
      {
        tab: "offline",
        headline: "Offline Ops",
        iconclass: "fa-duotone fa-file-alt",
      },
    ],
  },
  {
    tab: "currencies",
    headline: "Currencies",
    iconclass: "fa-duotone fa-coins",
  },
  {
    tab: "qcb",
    headline: "Final QC",
    iconclass: "fa-duotone fa-badge-check",
  },
  {
    tab: "checkout",
    headline: "Checkout",
    iconclass: "fa-duotone fa-paper-plane",
    tabs: [
      {
        active: true,
        tab: "filterSongs",
        headline: "Filter Songs",
        iconclass: "fa-duotone fa-filter-list",
      },
      {
        tab: "repository",
        headline: "Checkout to Repository",
        iconclass: "fa-duotone fa-files",
      },
      {
        tab: "results",
        headline: "Checkout to Portal",
        iconclass: "fa-duotone fa-person-to-portal",
      },
    ],
  },
];

const smallTabs = [
  {
    tab: "flyway",
    headline: "Flyway",
    iconclass: "fa-duotone fa-align-justify",
    active: true,
  },
];
// -------------------------------------------------------
// -------------------------------------------------------
const getPath = (tabs, ancestor, path) => {
  const newTabs = JSON.parse(JSON.stringify(tabs));
  for (var ii = 0; ii < newTabs.length; ii++) {
    const thisTab = newTabs[ii];
    if (thisTab.active) {
      const hasSubTabs = thisTab.hasOwnProperty("tabs");
      path.push({
        ancestor,
        tab: thisTab.tab,
        headline: thisTab.headline,
        hasSubTabs,
      });
      if (hasSubTabs) {
        getPath(thisTab.tabs, thisTab.tab, path);
      }
    }
  }
  return path;
};

// -------------------------------------------------------
// -------------------------------------------------------
const initialPath = getPath(initialTabs, "root", []);
const smallPath = getPath(smallTabs, "root", []);

const initialState__ = {
  mainTabs: smallTabs,
  path: smallPath,
  leaf: smallPath.at(-1).tab,
};

const initialState = {
  mainTabs: initialTabs,
  path: initialPath,
  leaf: initialPath.at(-1).tab,
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  let mainTabs;
  // -------------------------------------------------------
  switch (action.type) {
    // -------------------------------------------------------
    case "SET_SMALL_TABSET":
      return {
        ...state,
        mainTabs: smallTabs,
        path: smallPath,
        leaf: smallPath.at(-1).tab,
      };
    // -------------------------------------------------------
    case "SET_LARGE_TABSET":
      return {
        ...state,
        mainTabs: initialTabs,
        path: initialPath,
        leaf: initialPath.at(-1).tab,
      };
    // -------------------------------------------------------
    case "SET_COLLECTION":
      mainTabs = setProcessTab([...state.mainTabs], action.collection.headline);
      return {
        ...state,
        collectionid: action.collection.id,
        mainTabs,
      };

    // --------------------------------------------------------------
    case "SET_ACTIVE_TAB":
      mainTabs = setActive(
        action.current,
        action.ancestor,
        [...state.mainTabs],
        action.ancestor === "root"
      );
      const path = getPath(mainTabs, "root", []);
      const leaf = path.at(-1).tab;
      return {
        ...state,
        mainTabs,
        leaf,
        path,
      };
    // -------------------------------------------------------
    default:
      return state;
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
const setActive = (currentTab, ancestor, tabs, ancestral) => {
  const newTabs = JSON.parse(JSON.stringify(tabs));
  for (var ii = 0; ii < newTabs.length; ii++) {
    const thisTab = newTabs[ii];
    newTabs[ii] =
      thisTab.tab === currentTab
        ? { ...thisTab, active: true }
        : ancestral
        ? { ...thisTab, active: false }
        : thisTab;
    if (thisTab.tabs) {
      newTabs[ii].tabs = setActive(
        currentTab,
        ancestor,
        thisTab.tabs,
        thisTab.tab === ancestor
      );
    }
  }
  return newTabs;
};

// -------------------------------------------------------
// -------------------------------------------------------
const setProcessTab = (tabs, headline) => {
  const newTabs = JSON.parse(JSON.stringify(tabs));
  for (var ii = 0; ii < newTabs.length; ii++) {
    const thisTab = newTabs[ii];
    if (thisTab.tab === "process") {
      newTabs[ii].invisible = false;
      newTabs[ii].headline = headline;
    }
    if (thisTab.tabs) {
      newTabs[ii].tabs = setProcessTab(thisTab.tabs, headline);
    }
  }
  return newTabs;
};

export default reducer;
