import { useState } from "react";
// ------------------------------------------------------
import { retrieveAM } from "Redux/songs/actions";
import { retrieveGenius } from "Redux/songs/actions";
import { retrieveSonar } from "Redux/songs/actions";
import { useDispatch } from "react-redux";
// ------------------------------------------------------
// ------------------------------------------------------
const Research = ({ researchTitle, people, geniusIds, onCloseResearch }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("apple");
  const [apple, setApple] = useState(null);
  const [gettingApple, setGettingApple] = useState(false);
  const [genius, setGenius] = useState(null);
  const [gettingGenius, setGettingGenius] = useState(false);
  const [sonar, setSonar] = useState(null);
  const [gettingSonar, setGettingSonar] = useState(false);

  const fetchApple = async () => {
    setGettingApple(true);
    await dispatch(
      retrieveAM(researchTitle, people, (data) => {
        setApple(data);
        setGettingApple(false);
      })
    );
  };

  const fetchGenius = async () => {
    setGettingGenius(true);
    await dispatch(
      retrieveGenius(researchTitle, geniusIds, (data) => {
        setGenius(data);
        setGettingGenius(false);
      })
    );
  };

  const fetchSonar = async () => {
    setGettingSonar(true);
    await dispatch(
      retrieveSonar(researchTitle, people, (data) => {
        setSonar(data);
        setGettingSonar(false);
      })
    );
  };

  if (!researchTitle) return null;

  console.log("genius", genius);
  console.log("sonar", sonar);
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card sum-attributes" style={{ width: 900 }}>
        <header className="modal-card-head">
          <p className="modal-card-title">{researchTitle}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onCloseResearch}
          ></button>
        </header>

        <section className="modal-card-body">
          <Linkout person={people} item={researchTitle} />

          <div className="tabs">
            <ul>
              <li className={activeTab === "apple" ? "is-active" : ""}>
                <a onClick={() => setActiveTab("apple")}>
                  <span>Apple Music</span>
                </a>
              </li>
              <li className={activeTab === "genius" ? "is-active" : ""}>
                <a onClick={() => setActiveTab("genius")}>
                  <span>Genius</span>
                </a>
              </li>
              <li className={activeTab === "sonar" ? "is-active" : ""}>
                <a onClick={() => setActiveTab("sonar")}>
                  <span>Sonar</span>
                </a>
              </li>
            </ul>
          </div>
          {activeTab === "apple" && (
            <div className="box">
              {apple ? (
                <Apple apple={apple} />
              ) : (
                <div>
                  <button
                    className={`button is-small ${
                      gettingApple ? "is-loading" : ""
                    }`}
                    onClick={fetchApple}
                  >
                    Fetch Apple
                  </button>
                </div>
              )}
            </div>
          )}
          {activeTab === "genius" && (
            <div className="box">
              {genius ? (
                <Genius genius={genius} />
              ) : (
                <div>
                  <button
                    className={`button is-small ${
                      gettingGenius ? "is-loading" : ""
                    }`}
                    onClick={fetchGenius}
                  >
                    Fetch Genius
                  </button>
                </div>
              )}
            </div>
          )}
          {activeTab === "sonar" && (
            <div className="box">
              {sonar ? (
                <Sonar sonar={sonar} />
              ) : (
                <div>
                  <button
                    className={`button is-small ${
                      gettingSonar ? "is-loading" : ""
                    }`}
                    onClick={fetchSonar}
                  >
                    Fetch Sonar
                  </button>
                </div>
              )}
            </div>
          )}
        </section>

        <footer className="modal-card-foot">
          <button className="button" onClick={onCloseResearch}>
            Close
          </button>
        </footer>
      </div>
    </div>
  );
};
export default Research;

const Apple = ({ apple }) => {
  return (
    <div className="table-container">
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>Title</th>
            <th>Artist</th>
            <th>Release Date</th>
            <th>Credits</th>
          </tr>
        </thead>
        <tbody>
          {apple.map((item) => (
            <tr key={item.id}>
              <td>
                <div className="tag is-warning is-light">{item.title}</div>
                <a href={item.url} target="_blank">
                  {item.id}
                </a>
                <br />
              </td>
              <td>
                <div className="tag is-primary is-light">{item.artist}</div>
              </td>
              <td>
                <div className="tag is-info is-light">{item.release_date}</div>
              </td>
              <td>
                <div className="tags">
                  {item.writers
                    ? item.writers.map((writer) => (
                        <div className="tag is-small" key={writer}>
                          {writer}
                        </div>
                      ))
                    : ""}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Linkout = ({ person, item }) => {
  return (
    <div className="box">
      <div className="buttons has-addons">
        <button
          className="button is-small"
          onClick={() => {
            const uri = `https://www.discogs.com/search/?q=${item}&type=all&type=all`;
            window.open(uri);
          }}
        >
          DC
        </button>
        <button
          className="button is-small"
          onClick={() => {
            const uri = `https://genius.com/search?q=${item}&type=all&type=all`;
            window.open(uri);
          }}
        >
          Ge
        </button>

        <button
          className="button is-small"
          onClick={() => {
            const uri = `https://www.google.com/search?q="${item}" ${person}`;
            window.open(uri);
          }}
        >
          Goog
        </button>
        <button
          className="button is-small"
          onClick={() => {
            const uri = `https://www.youtube.com/results?search_query=${item}`;
            window.open(uri);
          }}
        >
          YT
        </button>
        <button
          className="button is-small"
          onClick={() => {
            const uri = `https://music.youtube.com/search?q=${item}`;
            window.open(uri);
          }}
        >
          YTM
        </button>
        <button
          className="button is-small"
          onClick={() => {
            const uri = `https://search.azlyrics.com/search.php?q=${item}`;
            window.open(uri);
          }}
        >
          AZ
        </button>
        <button
          className="button is-small"
          onClick={() => {
            const uri = `https://www.lyrics.com/lyrics/${item}`;
            window.open(uri);
          }}
        >
          LC
        </button>
      </div>
    </div>
  );
};

const Genius = ({ genius }) => {
  return (
    <div className="table-container">
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>Title</th>
            <th>Artist</th>
            <th>Featured Artists</th>
            <th>Release Date</th>
            <th>Credits</th>
          </tr>
        </thead>
        <tbody>
          {genius.map((item) => (
            <tr key={item.id}>
              <td>
                <div className="tag is-warning is-light">{item.title}</div>
                <br />
                <a href={item.url} target="_blank">
                  {item.genius_id}
                </a>
                <br />
              </td>
              <td>
                <div className="tag is-primary is-light">{item.artist}</div>
              </td>
              <td>
                <div className="tags">
                  {item.featured_artists &&
                    item.featured_artists.map((artist) => (
                      <div key={artist} className="tag is-success is-light">
                        {artist}
                      </div>
                    ))}
                </div>
              </td>
              <td>
                <div className="tag is-info is-light">{item.release_date}</div>
              </td>
              <td>
                <div className="tags">
                  {item.writer_artists &&
                    item.writer_artists.map((writer) => (
                      <div key={writer} className="tag">
                        {writer}
                      </div>
                    ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Sonar = ({ sonar }) => {
  return (
    <div className="table-container">
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>Title</th>
            <th>Artist</th>
            <th>Release Date</th>
            <th>Credits</th>
          </tr>
        </thead>
        <tbody>
          {sonar && (
            <tr key={sonar.id}>
              <td>
                <div className="tag is-warning is-light">{sonar.title}</div>
                <br />
                <a href={sonar.reference_url} target="_blank">
                  {sonar.reference_url}
                </a>
                <br />
              </td>
              <td>
                <div className="tag is-primary is-light">{sonar.artist}</div>
              </td>
              <td>
                <div className="tag is-info is-light">{sonar.release_date}</div>
              </td>
              <td>
                <div className="tags">
                  {sonar.composers &&
                    sonar.composers
                      .split(",")
                      .map((composer) => composer.trim())
                      .map((composer) => (
                        <div key={composer} className="tag">
                          {composer}
                        </div>
                      ))}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {sonar.is_certain ? <div className="box">{sonar.explanation}</div> : null}
    </div>
  );
};
