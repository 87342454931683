import { downloadDataFile, backend, apFly } from "System/system";
import {
  initializeAdvancedProgress,
  closeAdvancedProgress,
  updateAdvancedProgress,
} from "Redux/advancedprogress/actions";
import store from "System/mainStore";

// ------------------------------------------------------------
// ------------------------------------------------------------
export const retrieveSonar =
  (song_title, people, cb) => async (dispatch) => {
    console.log("Retrieving Sonar", song_title, people);
    const result = await apFly("norming/songs/sonar", {
      song_title,
      credits: JSON.stringify(people),
    });
    if (result.data) {
      cb(result.data.result);
      return;
    }
    cb(null);
  };

// ------------------------------------------------------------
// ------------------------------------------------------------
export const retrieveAM = (song_title, credits, cb) => async (dispatch) => {
  const result = await apFly("norming/songs/applemusic", {
    song_title,
    credits: JSON.stringify(credits),
  });
  if (result.data) {
    cb(result.data.result);
    return;
  }
  cb(null);
};

// ------------------------------------------------------------
// ------------------------------------------------------------
export const retrieveGenius = (song_title, geniusIDs, cb) => async (dispatch) => {
    console.log(song_title, geniusIDs);
  const result = await apFly("norming/songs/genius", {
    song_title,
    credits: JSON.stringify(geniusIDs.map((id) => id.toString())),
  });
  if (result.data) {
    cb(result.data.result);
    return;
  }
  cb(null);
};

// ------------------------------------------------------------
// ------------------------------------------------------------
const extractFeatured = (allMatches) => {
  if (!allMatches) return null;
  
  for (const match of allMatches) {
    if (!match.title.includes("feat.")) continue;
    let featured = null;
    const splitMatch = match.title.split("feat.");
    const title = splitMatch[0].replace("(", "");
    if (splitMatch.length >= 2) {
      featured = splitMatch[1].replace(")", "").trim();
    }
    match.title = title;
    match.featured_artists = featured;
  }
  return allMatches;
};

export const querySongs =
  (rubric, artists, geniusIDs, predictions, cb) => async (dispatch) => {
    let headline, credits;
    switch (rubric) {
      case "applemusic":
        headline = "Apple Music";
        credits = JSON.stringify(artists);
        break;
      case "genius":
        headline = "Genius";
        credits = JSON.stringify(geniusIDs.map((id) => id.toString()));
        break;
      case "sonar":
        headline = "Sonar";
        credits = JSON.stringify(artists);
        break;
    }

    predictions = JSON.parse(JSON.stringify(predictions));
    const datatype = "performer";

    const norming = store.getState().norming;
    const projects = store.getState().projects;
    const projectid = projects.project._id;
    const { data } = norming[datatype];

    const numberSongs = data.length;
    let canRun = true;
    dispatch(initializeAdvancedProgress(`Norming: ${headline}`, numberSongs, () => {
        canRun = false;
    }));

    for (var ii = 0; ii < data.length; ii++) {
      if (!canRun) {
        break;
      }
      if (
        !predictions[ii] ||
        predictions[ii] == -1 ||
        !predictions[ii][rubric] ||
        predictions[ii][rubric].length == 0
      ) {
        if (!predictions[ii] || predictions[ii] == -1) {
          predictions[ii] = {
            applemusic: [],
            genius: [],
            sonar: [],
          };
        }

        const song = data[ii]["Consensus_Title_SB"];
        dispatch(updateAdvancedProgress(ii + 1, song));

        const result = await apFly(`norming/songs/${rubric}`, {
          song_title: song,
          credits,
        });
        if (result.data) {
          let match, allMatches;
          switch (rubric) {
            case "applemusic":
              allMatches = result.data.result;
              extractFeatured(allMatches);
              match = allMatches ? allMatches[0] : null;
              break;
            case "genius":
              allMatches = result.data.result;
              match = allMatches ? allMatches[0] : null;
              break;
            case "sonar":
              match = result.data.result;
              allMatches = match && match.is_certain ? [match] : null;
              break;
          }
          console.log("--------------------------------");
          console.log("Song", song);
          console.log("All matches", allMatches);
          console.log("Match", match);
          console.log("--------------------------------");

          if (
            allMatches &&
            (Array.isArray(allMatches) ? allMatches.length > 0 : true)
          ) {
            const thisPrediction = predictions[ii];
            thisPrediction[rubric] = [
              match.artist,
              match.release_date,
              match.title,
              match.featured_artists,
              allMatches,
            ];
            predictions[ii] = thisPrediction;
            dispatch({
              type: "SET_PREDICTION",
              datatype,
              index: ii,
              value: thisPrediction,
            });
          }
        }
        await backend("norming", "update", {
          projectid,
          datatype,
          updateType: "predictions",
          dataString: JSON.stringify(predictions),
        });
      }
    }

    dispatch(closeAdvancedProgress());
    cb();
  };

// ------------------------------------------------------------
// ------------------------------------------------------------
export const queryAppleMusic =
  (artists, geniusIDs, predictions, cb) => async (dispatch) => {
    const headline = "Apple Music";
    const rubric = "applemusic";
    const credits = JSON.stringify(artists);

    predictions = JSON.parse(JSON.stringify(predictions));
    const datatype = "performer";

    const norming = store.getState().norming;
    const projects = store.getState().projects;
    const projectid = projects.project._id;
    const { data } = norming[datatype];

    const numberSongs = data.length;

    for (var ii = 0; ii < data.length; ii++) {
      document.title = `(${ii + 1}/${numberSongs}) ${headline}`;

      if (
        !predictions[ii] ||
        predictions[ii] == -1 ||
        !predictions[ii][rubric] ||
        predictions[ii][rubric].length == 0
      ) {
        if (!predictions[ii] || predictions[ii] == -1) {
          predictions[ii] = {
            applemusic: [],
            genius: [],
            sonar: [],
          };
        }

        const song = data[ii]["Consensus_Title_SB"];
        const result = await apFly(`norming/songs/${rubric}`, {
          song_title: song,
          credits,
        });
        if (result.data) {
          const matches = result.data.result;
          if (matches) {
            const match = matches[0];
            const thisPrediction = predictions[ii];
            thisPrediction[rubric] = [
              match.artist,
              match.release_date,
              match.title,
              matches,
            ];
            predictions[ii] = thisPrediction;
            dispatch({
              type: "SET_PREDICTION",
              datatype,
              index: ii,
              value: thisPrediction,
            });
          }
        }
        await backend("norming", "update", {
          projectid,
          datatype,
          updateType: "predictions",
          dataString: JSON.stringify(predictions),
        });
      }
    }

    document.title = "Bitsonic Flyway";
    cb();
  };

// ------------------------------------------------------------
// ------------------------------------------------------------
export const queryGenius =
  (artists, geniusIDs, predictions, cb) => async (dispatch) => {
    predictions = JSON.parse(JSON.stringify(predictions));
    const datatype = "performer";

    const norming = store.getState().norming;
    const projects = store.getState().projects;
    const projectid = projects.project._id;
    const { data } = norming[datatype];

    const numberSongs = data.length;

    for (var ii = 0; ii < data.length; ii++) {
      document.title = `(${ii + 1}/${numberSongs}) Genius`;

      if (!predictions[ii] || predictions[ii] == -1) {
        predictions[ii] = {
          applemusic: [],
          genius: [],
          sonar: [],
        };
      }

      const song = data[ii]["Consensus_Title_SB"];
      const result = await apFly("norming/songs/genius", {
        song_title: song,
        credits: JSON.stringify(geniusIDs.map((id) => id.toString())),
      });
      if (result.data) {
        const matches = result.data.result;
        if (matches && matches.length > 0) {
          const match = matches[0];
          const thisPrediction = predictions[ii];
          thisPrediction.genius = [
            match.artist,
            match.release_date,
            match.track,
            matches,
          ];
          predictions[ii] = thisPrediction;
          dispatch({
            type: "SET_PREDICTION",
            datatype,
            index: ii,
            value: thisPrediction,
          });
        }
      }
    }
    await backend("norming", "update", {
      projectid,
      datatype,
      updateType: "predictions",
      dataString: JSON.stringify(predictions),
    });

    document.title = "Bitsonic Flyway";
    cb();
  };

// ------------------------------------------------------------
// ------------------------------------------------------------
export const querySonar =
  (artists, geniusIDs, predictions, cb) => async (dispatch) => {
    predictions = JSON.parse(JSON.stringify(predictions));
    const datatype = "performer";

    const rubric = "sonar";
    const headline = "Sonar";
    const credits = JSON.stringify(artists);

    const norming = store.getState().norming;
    const projects = store.getState().projects;
    const projectid = projects.project._id;
    const { data } = norming[datatype];

    const numberSongs = data.length;

    for (var ii = 0; ii < data.length; ii++) {
      document.title = `(${ii + 1}/${numberSongs}) Sonar`;
      if (!predictions[ii] || predictions[ii] == -1) {
        predictions[ii] = {
          applemusic: [],
          genius: [],
          sonar: [],
        };
      }

      const song = data[ii]["Consensus_Title_SB"];
      const result = await apFly("norming/songs/sonar", {
        song_title: song,
        credits,
      });
      if (result.data) {
        const match = result.data.result;
        if (match && match.is_certain) {
          const thisPrediction = predictions[ii];
          thisPrediction.sonar = [
            match.performer,
            match.release_date,
            match.title,
          ];
          predictions[ii] = thisPrediction;
          dispatch({
            type: "SET_PREDICTION",
            datatype,
            index: ii,
            value: thisPrediction,
          });
        }
      }
    }
    await backend("norming", "update", {
      projectid,
      datatype,
      updateType: "predictions",
      dataString: JSON.stringify(predictions),
    });

    document.title = "Bitsonic Flyway";
    cb();
  };
