import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Switch from "react-switch";
// -------------------------------------------------------------
import { ClickableHeader } from "./ClickableHeader/";
import EditableTag from "../Shared/EditableTag";
import Number from "Components/Number";
// -------------------------------------------------------------
import {
  setSource,
  clearNorming,
  loadNorming,
  acceptAllSources,
} from "Redux/norming/actions";
import {
  classifySingleSource,
  batchClassifySources2,
  updateSourceTraining,
  getNumberOfTrainingPoints,
  doTraining,
} from "Redux/sources/actions";
import { notify } from "Redux/user/actions";
// -------------------------------------------------------------
import NormingButtons from "../Shared/NormingButtons";
import ProgressBar from "Components/ProgressBar";
// -------------------------------------------------------------
import waitbar from "Media/waitbar.gif";
import "./css.css";

const datatype = "source";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [loadingNorming, setLoadingNorming] = useState(false);
  const [predicting, setPredicting] = useState(false);
  const [reviewing, setReviewing] = useState(null);
  const [threshold, setThreshold] = useState(0.9);
  const [lowOnly, setLowOnly] = useState(false);
  const [newColumn, setNewColumn] = useState(null);
  const [training, setTraining] = useState(false);

  const [showMode, setShowMode] = useState("all");

  const norming = useSelector((state) => state.norming);
  const { data, predictions, unsynced } = norming[datatype];
  const { numberPoints } = useSelector((state) => state.sources);

  useEffect(() => {
    setLoadingNorming(true);
    dispatch(
      loadNorming(datatype, () => {
        dispatch(getNumberOfTrainingPoints());
        setLoadingNorming(false);
      })
    );
  }, []);

  useEffect(() => {
    const newNewColumn = [];
    for (var ii = 0; ii < data.length; ii++) {
      const row = data[ii];
      newNewColumn[ii] = `pred-${ii}`;
    }
    setNewColumn(newNewColumn);
  }, [data]);

  if (loadingNorming) return <img src={waitbar} />;
  if (!newColumn) return null;

  const onSwitchChange = (checked) => {
    setLowOnly(checked);
  };

  const batchClassify__ = async () => {
    setPredicting(true);

    const tik = Date.now();
    for (let index = 0; index < 100; index++) {
      const inputString = data[index]["Source_SB"];
      const outputString = await classifySingleSource(inputString);
      setNewColumn((prevColumn) => {
        const updatedColumn = [...prevColumn];
        updatedColumn[index] = outputString;
        return updatedColumn;
      });
    }
    const tok = Date.now();
    console.log("Time taken in seconds:", (tok - tik) / 1000);
    setPredicting(false);
  };

  const batchClassify = async () => {
    setPredicting(true);
    await dispatch(batchClassifySources2());
    setPredicting(false);
  };

  return (
    <div>
      <ProgressBar />
      <NormingButtons
        size={data.length}
        unsynced={unsynced}
        datatype={datatype}
      >
        <td>
          <div
            className={`button is-small ${predicting ? "is-loading" : ""}`}
            onClick={batchClassify}
          >
            <span className="icon is-small">
              <i className="fas fa-network-wired" />
            </span>
            <span>Classify</span>
          </div>
        </td>
        <td>&nbsp;</td>
        <td>
          <div
            className={`button is-small`}
            onClick={() => {
              if (
                window.confirm("Are you sure? This cannot be undone.") == true
              ) {
                dispatch(clearNorming(datatype));
              }
            }}
          >
            <span className="icon is-small">
              <i className="fas fa-trash-alt" />
            </span>
            <span>Clear</span>
          </div>
        </td>
        <td>&nbsp;</td>
        {numberPoints ? (
          <button
            className={`button is-small ${training ? "is-loading" : ""}`}
            onClick={() => {
              setTraining(9);
              dispatch(
                doTraining(() => {
                  setTraining(false);
                })
              );
            }}
          >
            <span className="icon is-small">
              <i className="fa-duotone fa-solid fa-microchip fa-beat-fade" />
            </span>
            <span>Update ({numberPoints})</span>
          </button>
        ) : null}
        <td>
          <Threshold threshold={threshold} setThreshold={setThreshold} />
        </td>
        <td>&nbsp;</td>
        <td>
          <div
            className={`button is-small`}
            onClick={() => {
              if (window.confirm("Are you sure?") == true) {
                dispatch(acceptAllSources(0));
              }
            }}
          >
            <span className="icon is-small">
              <i className="fas fa-check" />
            </span>
            <span>Accept All</span>
          </div>
        </td>
        <td>&nbsp;</td>
        <td>
          <div className="select is-small">
            <select
              value={showMode}
              onChange={(evt) => {
                setShowMode(evt.target.value);
              }}
            >
              <option value="all">Show All</option>
              <option value="no-highlights">Non-highlighted</option>
              <option value="low-confidence">Below Threshold</option>
              <option value="unaccepted">Empty Normalized</option>
            </select>
          </div>
        </td>
      </NormingButtons>

      <MainTable
        norming={norming}
        setReviewing={setReviewing}
        threshold={threshold}
        hideAccepted={lowOnly}
        newColumn={newColumn}
        showMode={showMode}
      />
    </div>
  );
};
export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const Threshold = ({ threshold, setThreshold }) => {
  const [value, setValue] = useState(threshold);
  const onSubmit = (evt) => {
    if (value > 0 && value <= 1) {
      setThreshold(value);
      return;
    }
    setValue(threshold);
  };

  return (
    <input
      className="input is-small"
      type="text"
      placeholder="Set Confidence Threshold"
      value={value}
      onChange={(evt) => {
        setValue(evt.target.value);
      }}
      onBlur={onSubmit}
      onKeyDown={(evt) => {
        if (evt.key === "Enter") onSubmit(evt);
      }}
    />
  );
};

const calcHasHighlights = (text, highlights) => {
  return highlights.some((highlight) => text.includes(highlight));
};

// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({
  norming,
  hideAccepted,
  threshold,
  newColumn,
  showMode,
}) => {
  const dispatch = useDispatch();
  const datatype = "source";
  const { source } = norming;

  const [activeTab, setActiveTab] = useState("royalty-down");
  const [searchStringNormalized, setSearchStringNormalized] = useState("");
  const [searchStringSource, setSearchStringSource] = useState("");
  const [searchStringPrediction, setSearchStringPrediction] = useState("");

  if (!source.data) return null;
  if (!source.data.length) return null;
  const { data, predictions } = source;

  // -------------------------------------------------------------
  const percent = (x, total) => {
    return x / total;
  };

  let totalCount = 0;
  for (var row of data) {
    totalCount += row["Count"];
  }
  let totalRoyalties = 0;
  if (data[0].hasOwnProperty("Total_Royalty")) {
    for (var row of data) {
      if (row.hasOwnProperty("Total_Royalty")) {
        totalRoyalties += row["Total_Royalty"];
      }
    }
  }
  // -------------------------------------------------------------
  let sortFunc;
  switch (activeTab) {
    case "source-up":
      sortFunc = (a, b) => a["Source_SB"].localeCompare(b["Source_SB"]);
      break;
    case "source-down":
      sortFunc = (a, b) => b["Source_SB"].localeCompare(a["Source_SB"]);
      break;
    case "count-up":
      sortFunc = (a, b) => a["Count"] - b["Count"];
      break;
    case "count-down":
      sortFunc = (a, b) => b["Count"] - a["Count"];
      break;
    case "royalty-up":
      sortFunc = (a, b) => a["Total_Royalty"] - b["Total_Royalty"];
      break;
    case "royalty-down":
      sortFunc = (a, b) => b["Total_Royalty"] - a["Total_Royalty"];
      break;
  }

  const sortedIndices = data.map((row, index) => index);
  sortedIndices.sort((a, b) => sortFunc(data[a], data[b]));

  const sortedData = sortedIndices.map((index) => data[index]);
  const sortedPredictions = sortedIndices.map((index) => predictions[index]);

  return (
    <div className="table-wrapper">
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>
              <div className="tabs is-small is-toggle is-toggle-rounded">
                <ul>
                  <li
                    className={
                      activeTab.startsWith("source") ? "is-active" : ""
                    }
                  >
                    <a
                      onClick={() =>
                        setActiveTab(
                          activeTab === "source-down"
                            ? "source-up"
                            : "source-down"
                        )
                      }
                    >
                      <span>Source</span>
                      {activeTab.startsWith("source") && (
                        <span className="icon is-small">
                          <i
                            className={`fas fa-chevron-${
                              activeTab === "source-up" ? "up" : "down"
                            }`}
                          ></i>
                        </span>
                      )}
                    </a>
                  </li>
                  <li
                    className={activeTab.startsWith("count") ? "is-active" : ""}
                  >
                    <a
                      onClick={() =>
                        setActiveTab(
                          activeTab === "count-down" ? "count-up" : "count-down"
                        )
                      }
                    >
                      <span>Count</span>
                      {activeTab.startsWith("count") && (
                        <span className="icon is-small">
                          <i
                            className={`fas fa-chevron-${
                              activeTab === "count-up" ? "up" : "down"
                            }`}
                          ></i>
                        </span>
                      )}
                    </a>
                  </li>
                  <li
                    className={
                      activeTab.startsWith("royalty") ? "is-active" : ""
                    }
                  >
                    <a
                      onClick={() =>
                        setActiveTab(
                          activeTab === "royalty-down"
                            ? "royalty-up"
                            : "royalty-down"
                        )
                      }
                    >
                      <span>Royalty</span>
                      {activeTab.startsWith("royalty") && (
                        <span className="icon is-small">
                          <i
                            className={`fas fa-chevron-${
                              activeTab === "royalty-up" ? "up" : "down"
                            }`}
                          ></i>
                        </span>
                      )}
                    </a>
                  </li>
                </ul>
              </div>
            </th>
            <th>
              Original Source
              <br />
              <input
                type="text"
                className="input is-small"
                value={searchStringSource}
                onChange={(evt) => setSearchStringSource(evt.target.value)}
              />
            </th>
            <th>Override</th>
            <th>
              Normalized
              <br />
              <input
                type="text"
                className="input is-small"
                value={searchStringNormalized}
                onChange={(evt) => setSearchStringNormalized(evt.target.value)}
              />
            </th>
            <th>
              Classifier
              <br />
              <input
                type="text"
                className="input is-small"
                value={searchStringPrediction}
                onChange={(evt) => setSearchStringPrediction(evt.target.value)}
              />
            </th>
            <th>Confidence</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, rowindex) => {
            const sortedRowindex = sortedIndices[rowindex];

            if (searchStringNormalized && searchStringNormalized !== "~") {
              const matchingString =
                searchStringNormalized.at(0) === "~"
                  ? searchStringNormalized.slice(1)
                  : searchStringNormalized;

              const isMatch = row["Normalized_Source_9LC"]
                .toUpperCase()
                .includes(matchingString.toUpperCase());
              const isPositive = searchStringNormalized.at(0) !== "~";
              if (!isMatch && isPositive) return null;
              if (isMatch && !isPositive) return null;
            }

            if (searchStringSource && searchStringSource !== "~") {
              const matchingString =
                searchStringSource.at(0) === "~"
                  ? searchStringSource.slice(1)
                  : searchStringSource;

              const isMatch = row["Source_SB"]
                .toUpperCase()
                .includes(matchingString.toUpperCase());
              const isPositive = searchStringSource.at(0) !== "~";
              if (!isMatch && isPositive) return null;
              if (isMatch && !isPositive) return null;
            }

            if (searchStringPrediction && searchStringPrediction !== "~") {
              const matchingString =
                searchStringPrediction.at(0) === "~"
                  ? searchStringPrediction.slice(1)
                  : searchStringPrediction;

              const isMatch = predictions[rowindex][0]
                .toUpperCase()
                .includes(matchingString.toUpperCase());
              const isPositive = searchStringPrediction.at(0) !== "~";
              if (!isMatch && isPositive) return null;
              if (isMatch && !isPositive) return null;
            }

            const highlights =
              typeof row["Normalized_Source_9LC"] === "string"
                ? row["Normalized_Source_9LC"]
                    .split(" ")
                    .map((x) => x.trim())
                    .filter((x) => x.length > 0)
                    .filter((x) => x !== "-")
                : [];

            const hasHighlights = calcHasHighlights(
              row["Source_SB"],
              highlights
            );

            const aboveThreshold =
              sortedPredictions[rowindex] !== -1
                ? parseFloat(sortedPredictions[rowindex][1]) > threshold
                : false;
            const thresholdClass = aboveThreshold ? "" : "not-above";

            switch (showMode) {
              case "unaccepted":
                if (row["Normalized_Source_9LC"] !== "") return null;
                break;
              case "no-highlights":
                if (hasHighlights) return null;
                break;
              case "low-confidence":
                if (aboveThreshold) return null;
                break;
            }

            return (
              <tr key={`row-${rowindex}`}>
                <td>
                  <small>
                    Count: <Number value={row["Count"]} isRounded={9} /> (
                    <Number
                      value={percent(row["Count"], totalCount)}
                      isPercentage={9}
                    />
                    )
                    <br />
                    {row.hasOwnProperty("Total_Royalty") && (
                      <div>
                        Nominal:{" "}
                        <Number value={row["Total_Royalty"]} isRounded={9} /> (
                        <Number
                          value={percent(row["Total_Royalty"], totalRoyalties)}
                          isPercentage={9}
                        />
                        )
                      </div>
                    )}
                  </small>
                </td>
                <td>
                  <Highlight text={row["Source_SB"]} highlights={highlights} />
                </td>
                <td>
                  <Override
                    source={row["Source_SB"]}
                    rowindex={sortedRowindex}
                    prediction={sortedPredictions[rowindex]}
                  />
                </td>
                <td>
                  <EditableTag
                    currentValue={row["Normalized_Source_9LC"]}
                    rowindex={sortedRowindex}
                    className="tag is-info is-light"
                    updateData={setSource}
                  />
                </td>
                <td>
                  {sortedPredictions[rowindex] !== -1 ? (
                    <div
                      className={`tag is-primary is-light ${thresholdClass} is-clickable`}
                      onClick={() => {
                        dispatch(
                          setSource(
                            sortedRowindex,
                            sortedPredictions[rowindex][0]
                          )
                        );
                      }}
                    >
                      {sortedPredictions[rowindex][0]}
                    </div>
                  ) : null}
                </td>
                <td>{sortedPredictions[rowindex][1]?.toFixed(2)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

// -------------------------------------------------------------
// -------------------------------------------------------------
const Override = ({ rowindex, prediction, source }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(prediction[0]);
  const [mode, setMode] = useState("init");

  useEffect(() => {
    setValue(prediction[0]);
  }, [prediction]);

  if (prediction === -1) return null;

  const submit = async () => {
    if (value !== prediction[0]) {
      setMode("modifying");
      const embeddingID = prediction[2];
      dispatch(
        updateSourceTraining(rowindex, source, value, embeddingID, () => {
          setMode("init");
          dispatch(setSource(rowindex, value));
          dispatch(notify("Source updated", "New data point incorporated.", 5));
        })
      );
      return;
    }
    setMode("init");
  };

  switch (mode) {
    case "init":
      return (
        <div className="tag is-info" onClick={() => setMode("editing")}>
          {value}
        </div>
      );
    case "editing":
      return (
        <input
          className="input"
          type="text"
          value={value}
          autoFocus={true}
          onChange={(evt) => setValue(evt.target.value)}
          onKeyDown={(evt) => evt.key === "Enter" && submit()}
          onBlur={submit}
        />
      );
    case "modifying":
      return (
        <div
          onClick={() => setMode("init")}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i className="fa-duotone fa-solid fa-microchip fa-beat-fade" />
          &nbsp;Rewiring&nbsp;my&nbsp;brain...{" "}
        </div>
      );
  }
};

const Highlight = ({ text, highlights }) => {
  let hits = [];
  for (const highlight of highlights) {
    if (text.indexOf(highlight) !== -1) {
      const ini = text.indexOf(highlight);
      const fin = ini + highlight.length;
      hits.push({ ini, fin });
    }
  }
  hits = hits.sort((a, b) => (a.ini < b.ini ? -1 : 1));
  if (hits.length == 0) return <div className="tag">{text}</div>;
  let mergedHits = [];
  let prevFin;
  for (var ii in hits) {
    if (ii > 0) {
      if (hits[ii - 1].fin > hits[ii].ini) {
        mergedHits.pop();
        mergedHits.push({
          ini: hits[ii - 1].ini,
          fin: hits[ii].fin,
        });
      } else mergedHits.push(hits[ii]);
      prevFin = hits[ii - 1].fin;
    } else mergedHits.push(hits[ii]);
  }

  const frags = [];
  if (mergedHits[0].ini > 0)
    frags.push({ text: text.slice(0, mergedHits[0].ini), type: "regular" });

  for (var ii = 0; ii < mergedHits.length; ii++) {
    const fin = mergedHits[ii].fin;
    const ini = mergedHits[ii].ini;
    frags.push({ text: text.slice(ini, fin), type: "highlight" });
    if (ii < mergedHits.length - 1) {
      frags.push({
        text: text.slice(fin, mergedHits[ii + 1].ini),
        type: "regular",
      });
    }
  }
  if (mergedHits[mergedHits.length - 1].fin < text.length) {
    frags.push({
      text: text.slice(mergedHits[mergedHits.length - 1].fin, text.length),
      type: "regular",
    });
  }
  return (
    <div className="tags has-addons">
      {frags.map((frag, ii) => {
        const colorClass = frag.type === "regular" ? "" : "is-warning";
        return (
          <div key={`frag-${ii}`} className={`tag ${colorClass}`}>
            {frag.text.trim()}
          </div>
        );
      })}
    </div>
  );
};

/*
// -------------------------------------------------------
// -------------------------------------------------------
const PredictedTag = ({ prediction }) => {
  if (prediction === undefined) return null;
  if (prediction === null)
    return (
      <div>
        <img src={running} />
      </div>
    );
  const predictedText = prediction === "X" ? "PR" : "Song";
  return <div className="tag">{predictedText}</div>;
};
// -------------------------------------------------------
// -------------------------------------------------------
const Review = ({ reviewing, onClose, onInc, onDec }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    checkPrediction();
  }, [reviewing]);

  const dispatch = useDispatch();

  const norming = useSelector((state) => state.norming);
  const { source } = norming;
  const { data, reviewed, predictions } = source;
  const dataSize = data.length;
  const numberReviewed = reviewed.filter((x) => x === true).length;

  const canDec = reviewing > 0;
  const canInc = reviewing < dataSize - 1;

  const row = data[reviewing];
  const prediction = predictions[reviewing];

  const checkPrediction = () => {
    if (predictions[reviewing] === undefined) {
      dispatch(predictSource(data, reviewing));
    }
  };

  const onApprove = () => {
    dispatch(setPredictionApproval("source", reviewing, true));
    if (canInc) {
      onInc();
    }
  };

  const onEdit = (evt) => {
    setValue(evt.target.value.toUpperCase());
  };

  const onSubmit = (evt) => {
    dispatch(setSource(reviewing, value));
    setEditing(false);
  };

  const isApproved = reviewed[reviewing];

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head convert">
          <div className="review-wrapper">
            <p className="modal-card-title">
              Review ({reviewing}/{dataSize})
            </p>
            <div className="review-progress">
              <progress
                className="convert progress is-primary"
                value={numberReviewed}
                max={source.data.length}
              />
            </div>
            <button className="delete" onClick={onClose} />
          </div>
        </header>
        <section className="modal-card-body">
          <div className="title">
            {row["Source_SB"]}
            <div className="status-wrapper">
              {isApproved ? (
                <div className="tag is-success is-light is-small">
                  <i className="fa fa-check" />
                  &nbsp;Approved
                </div>
              ) : (
                <div className="tag is-danger is-light is-small">
                  <i className="fa fa-times" />
                  &nbsp;Not yet approved
                </div>
              )}
            </div>
          </div>
          <div className="table-container">
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th>Classification</th>
                  <th>Predicted</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {editing ? (
                      <input
                        className="input"
                        value={value}
                        onChange={onEdit}
                        onKeyDown={(evt) => evt.key === "Enter" && onSubmit()}
                        onBlur={(evt) => setEditing(false)}
                      />
                    ) : (
                      <div
                        className="tag is-info is-light is-clickable"
                        onClick={() => {
                          setValue(row["Normalized_Source_9LC"]);
                          setEditing(true);
                        }}
                      >
                        {row["Normalized_Source_9LC"]}
                      </div>
                    )}
                  </td>
                  <td>
                    {typeof prediction !== "string" ? (
                      <div>
                        <img src={running} />
                      </div>
                    ) : (
                      <div
                        className="tag is-clickable"
                        onClick={() => {
                          dispatch(setSource(reviewing, prediction));
                        }}
                      >
                        {prediction}
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <footer className="modal-card-foot">
          {isApproved ? (
            <div className="footer-buttons">
              <button
                className="button is-pulled-left"
                onClick={onDec}
                disabled={!canDec}
              >
                <span className="icon is-small">
                  <i className="fa fa-chevron-left" />
                </span>
                <span>Previous</span>
              </button>

              <button
                className="button is-pulled-right is-primary"
                onClick={onInc}
              >
                <span>Next</span>
                <span className="icon is-small">
                  <i className="fa fa-chevron-right" />
                </span>
              </button>
              <button
                className="button is-pulled-left"
                disabled={!canInc}
                onClick={() => {
                  dispatch(setPredictionApproval("pool", reviewing, false));
                }}
              >
                <span>Un-Approve</span>
              </button>
            </div>
          ) : (
            <div className="footer-buttons">
              <button
                className="button is-pulled-left"
                onClick={onDec}
                disabled={!canDec}
              >
                <span className="icon is-small">
                  <i className="fa fa-chevron-left" />
                </span>
                <span>Previous</span>
              </button>
              <button
                className="button is-primary is-pulled-right"
                disabled={!canInc}
                onClick={onApprove}
              >
                <span>Approve</span>
                <span className="icon is-small">
                  <i className="fa fa-chevron-right" />
                </span>
              </button>

              <button className="button is-pulled-left" onClick={onInc}>
                <span>Next</span>
                <span className="icon is-small">
                  <i className="fa fa-chevron-right" />
                </span>
              </button>
            </div>
          )}
        </footer>
      </div>
    </div>
  );
};
*/
