import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//import queryString from "query-string";
import axios from "axios";
// -------------------------------------------------------------
// -------------------------------------------------------------
import { showPivotPopup, updatePivot } from "Redux/projects/actions";
import {
  getSpotifyArtist,
  getGeniusArtist,
  getGeniusId,
} from "Redux/norming/actions";
// -------------------------------------------------------------
const genius_token =
  "NIiU34YIFZaoRosyzIUS8fa8MqDWL6ZBRANBn7gqCbdCxyrTkx7OBODL6g_6WhET";
// -------------------------------------------------------------

const clients = [
  { entity: "Dynamite" },
  { entity: "Seasons Group" },
  { entity: "Corrum Capital" },
  { entity: "AMR" },
  { entity: "Alternative" },
  { entity: "ARC" },
  { entity: "Art Issue" },
  { entity: "BitSonic" },
  { entity: "Bitter and Twisted Management" },
  { entity: "CC Young" },
  { entity: "Concord" },
  { entity: "Cowbell" },
  { entity: "Crescendo" },
  { entity: "David Ravden" },
  { entity: "GRF" },
  { entity: "LPG" },
  { entity: "Hipgnosis" },
  { entity: "Influence Media" },
  { entity: "Kilometre" },
  { entity: "Letter One" },
  { entity: "Lyric" },
  { entity: "Mojo" },
  { entity: "Music Law" },
  { entity: "Music Royalties Inc" },
  { entity: "Nine Lives Capital" },
  { entity: "Primary Wave" },
  { entity: "Royalty Earner" },
  { entity: "Songvest" },
  { entity: "Tailwind" },
  { entity: "Tempo" },
  { entity: "Utopia" },
];

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({}) => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects);

  const [data, setData] = useState({});
  const [uri, setURI] = useState("");
  const [id, setId] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [searchGenius, setSearchGenius] = useState("");

  const onSearchGenius = (evt) => {
    const { value } = evt.target;
    setSearchGenius(value);
    if (parseInt(value) > 0) {
      return getArtistById(value);
    }
    if (value) {
      return getArtist(value);
    }
    setCandidates([]);
  };

  useEffect(() => {
    if (projects.project) {
      const project = projects.project;
      let { pivot, client, keyType, artists, keys } = project;
      if (!keys) keys = [{ pivot: pivot, keyType: keyType, discogsid: "" }];
      if (!artists) artists = [];
      setData({
        pivot,
        client,
        keyType,
        artists,
        keys,
      });
    }
  }, [projects.project]);

  const getArtist = async (query) => {
    const result = await axios({
      method: "get",
      url: `https://api.genius.com/search?access_token=${genius_token}&q=${query}`,
      headers: {},
    });
    if (result.data.response && result.data.response.hits) {
      const ids = [],
        artists = [];
      for (const hit of result.data.response.hits) {
        const artist = hit.result.primary_artist;
        const { id, name, url } = artist;
        if (!ids.includes(id)) {
          ids.push(id);
          artists.push({ artist: name, id, uri: url });
        }
      }
      setCandidates(artists);
    }
  };

  const getArtistById = async (id) => {
    const result = await axios({
      method: "get",
      url: `https://api.genius.com/artists/${id}?access_token=${genius_token}`,
      headers: {},
    });
    if (result.data.response) {
      const { artist } = result.data.response;
      const { id, name, url } = artist;
      setCandidates([{ artist: name, id, uri: url }]);
    }
    console.log("Genius results", result);
  };

  console.log(candidates);

  const submit = () => {
    dispatch(updatePivot(data));
  };

  const onChange = (evt) => {
    setData({
      ...data,
      [evt.target.id]: evt.target.value,
    });
    setHasChanged(true);
  };

  const onChangeKey = (evt, keyindex) => {
    const keys = [...data.keys];
    keys[keyindex][evt.target.id] = evt.target.value;
    setData({ ...data, keys });
    setHasChanged(true);
  };

  const addKey = () => {
    setData({
      ...data,
      keys: [
        ...data.keys,
        {
          pivot: "",
          keyType: "Artist",
          discogsid: "",
        },
      ],
    });
    setHasChanged(true);
  };

  const removeKey = (keyindex) => {
    setData({
      ...data,
      keys: [...data.keys].filter((x, i) => i !== keyindex),
    });
  };

  /*const setArtist = (index, artist) => {
    setData({
      ...data,
      artists: data.artists.map((x, i) => (i === index ? artist : x)),
    });
    setHasChanged(true);
  };*/

  const removeArtist = (id) => {
    setData({
      ...data,
      artists: data.artists.filter((x, i) => x.id !== id),
    });
    setHasChanged(true);
  };

  const addArtist = (candidate) => {
    if (!data.artists.map((x) => x.id).includes(candidate.id)) {
      const { id, artist, uri } = candidate;
      setData({
        ...data,
        artists: [...data.artists, { id, artist, uri }],
      });
      setHasChanged(true);
    }
  };

  if (!projects.project) return null;
  if (!data.keys) return null;

  const changeURIGen = (evt) => {
    setURI(evt.target.value);
  };

  const onSubmit = async () => {
    //"https://genius.com/artists/Steve-russell"
    if (uri) {
      const res = await getGeniusId(uri);
      if (res.data) {
        setId(res.data.id);
        setURI("");
      }
    }
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card medium-screen">
        <header className="modal-card-head">
          <p className="modal-card-title">{projects.project.title}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => {
              dispatch(showPivotPopup(false));
            }}
          />
        </header>

        <section className="modal-card-body">
          <div className="box">
            <div className="field">
              <label className="label">Client</label>
              <div className="control has-icons-left has-icons-right">
                <select
                  className="input  "
                  type="text"
                  placeholder="Enter Catalog Key Entity"
                  value={data.client}
                  id="client"
                  onChange={onChange}
                >
                  {clients.map((client, clientindex) => (
                    <option key={`client-${clientindex}`}>
                      {client.entity}
                    </option>
                  ))}
                </select>
                <span className="icon is-small is-left">
                  <i className="fas fa-building"></i>
                </span>
              </div>
            </div>
          </div>

          <div className="box">
            Discogs IDs
            <div className="table-container">
              <table className="table table-bordered is-fullwidth key-table">
                <thead>
                  <tr>
                    <th>Key Person</th>
                    <th>Key Person Type</th>
                    <th>Discogs ID</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.keys.map((key, keyindex) => {
                    return (
                      <tr key={`keyrows-${keyindex}`}>
                        <td>
                          <div className="field">
                            <div className="control has-icons-left has-icons-right">
                              <input
                                onChange={(evt) => {
                                  onChangeKey(evt, keyindex);
                                }}
                                autoFocus={true}
                                className="input  "
                                type="text"
                                placeholder="Enter Catalog Key Entity"
                                value={key.pivot}
                                id="pivot"
                              />
                              <span className="icon is-small is-left">
                                <i className="fas fa-user"></i>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="field">
                            <div className="control has-icons-left has-icons-right">
                              <select
                                onChange={(evt) => {
                                  onChangeKey(evt, keyindex);
                                }}
                                autoFocus={true}
                                className="input  "
                                type="text"
                                placeholder="Key Person Type"
                                value={key.keyType}
                                id="keyType"
                              >
                                <option>Artist</option>
                                <option>Writer</option>
                                <option>Performer</option>
                                <option>Producer</option>
                                <option>Composer</option>
                                <option>Manager</option>
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fas fa-tags"></i>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="field">
                            <div className="control has-icons-left has-icons-right">
                              <input
                                onChange={(evt) => {
                                  onChangeKey(evt, keyindex);
                                }}
                                autoFocus={true}
                                className="input  "
                                type="text"
                                placeholder="Enter Catalog Key Entity"
                                value={key.discogsid}
                                id="discogsid"
                              />
                              <span className="icon is-small is-left">
                                <i className="fas fa-id-card"></i>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <button
                            className="delete"
                            onClick={() => {
                              removeKey(keyindex);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <button className="button" onClick={addKey}>
              <span className="icon is-small">
                <i className="fa fa-plus-circle" />
              </span>
            </button>
          </div>
          <div className="columns">
            <div
              className="column is-half"
              style={{ height: 300, overflowY: "auto" }}
            >
              <nav className="panel">
                <p className="panel-heading">Genius.com Artists</p>
                <div className="panel-block">
                  <p className="control has-icons-left">
                    <input
                      className="input"
                      type="text"
                      placeholder="Search"
                      onChange={onSearchGenius}
                      value={searchGenius}
                    />
                    <span className="icon is-left">
                      <i className="fas fa-search" aria-hidden="true"></i>
                    </span>
                  </p>
                </div>
                {candidates.map((candidate, candidateindex) => {
                  return (
                    <a
                      className="panel-block spread"
                      key={`candidate-${candidateindex}`}
                    >
                      <div
                        className="search-result-id"
                        onClick={() => {
                          window.open(candidate.uri, "_blank");
                        }}
                      >
                        <span className="panel-icon">
                          <i className="fas fa-user" aria-hidden="true" />
                        </span>
                        {candidate.artist}
                      </div>
                      <button
                        className="button is-small"
                        onClick={() => {
                          addArtist(candidate);
                        }}
                      >
                        <span className="icon is-small">
                          <i className="fas fa-plus" aria-hidden="true" />
                        </span>
                      </button>
                    </a>
                  );
                })}
              </nav>
            </div>
            <div className="column is-half">
              <div className="box">
                <div className="field is-grouped is-grouped-multiline">
                  {data.artists.map((artist, artistindex) => {
                    return (
                      <div className="control" key={`artist-${artistindex}`}>
                        <div className="tags has-addons">
                          <div
                            className="tag is-info is-light is-clickable"
                            onClick={() => {
                              window.open(artist.uri, "_blank");
                            }}
                          >
                            {artist.artist}
                          </div>
                          <div
                            className="tag is-delete is-clickable"
                            onClick={() => {
                              removeArtist(artist.id);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="modal-card-foot">
          <button
            className="button is-primary"
            disabled={!hasChanged}
            onClick={submit}
          >
            Update
          </button>

          <button
            className="button"
            onClick={() => {
              dispatch(showPivotPopup(false));
            }}
          >
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Index;

// ----------------------------------------------
// ----------------------------------------------
const GeniusArtist = ({ spartist, index, setArtist, removeArtist }) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(!spartist.id);
  const [who, setWho] = useState(spartist.artist);
  const [id, setId] = useState(spartist.id);
  const [uri, setUri] = useState(spartist.uri);

  const onChange = async (evt) => {
    setId(evt.target.value);
  };

  const onSubmit = async () => {
    if (id) {
      setEditing(false);
      const res = await dispatch(getGeniusArtist(id));

      if (res.data) {
        console.log(res.data.artist);
        console.log(res.data.uri);

        setWho(res.data.artist);
        setUri(res.data.uri);
        setArtist(index, {
          id,
          artist: res.data.artist,
          uri: res.data.uri,
        });
      }
    }
  };

  return (
    <div className="control">
      {editing ? (
        <div>
          <input
            className="input is-small"
            type="text"
            value={id}
            onChange={onChange}
            autoFocus={true}
            onKeyDown={(evt) => evt.key === "Enter" && onSubmit()}
            onBlur={onSubmit}
          />
        </div>
      ) : (
        <div className="tags has-addons">
          <div className="tag is-info is-light is-clickable">Moinsen Ewald</div>
          <div className="tag is-delete is-clickable"></div>
        </div>
      )}
    </div>
  );
};

/*
          <div className="box">
            Genius IDs
            <div className="field is-grouped is-grouped-multiline">
              {data.artists.map((artist, idx) => (
                <GeniusArtist
                  key={`spartists-${idx}`}
                  index={idx}
                  spartist={artist}
                  setArtist={setArtist}
                  removeArtist={removeArtist}
                />
              ))}
              <div className="control">
                <div className="tags has-addons">
                  <div className="tag is-info is-light" onClick={addArtist}>
                    <span className="icon is-small">
                      <i className="fas fa-plus" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <input
              className="input"
              type="text"
              onChange={changeURIGen}
              value={uri}
              onKeyDown={(evt) => evt.key === "Enter" && onSubmit()}
              onBlur={onSubmit}
            />
            <div>ID: {id}</div>
          </div>

*/
