const initialState = {
  title: null,
  status: null,
  current: null,
  total: null,
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  // -------------------------------------------------------
  switch (action.type) {
    // --------------------------------------------------------------
    case "INITIALIZE_ADVANCED_PROGRESS":
      document.title = action.title;
      return {
        ...state,
        title: action.title,
        status: action.status || "Initializing...",
        onClose: action.onClose,
        total: action.total,
        current: 0,
      };

    // --------------------------------------------------------------
    case "UPDATE_ADVANCED_PROGRESS":
      document.title = `${action.current}/${state.total}: ${action.status}`;
      return {
        ...state,
        current: action.current,
        status: `${action.current}/${state.total}: ${action.status}`,
      };

    // --------------------------------------------------------------
    case "CLOSE_ADVANCED_PROGRESS":
      document.title = "Bitsonic Flyway";
      return { ...initialState };

    // -------------------------------------------------------
    default:
      return state;
  }
};

export default reducer;
