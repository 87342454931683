// -------------------------------------------------------------
// -------------------------------------------------------------
const currencies = [
  {
    currency: "gbp",
    symbol: "£",
  },
  {
    currency: "krw",
    symbol: "₩",
  },
  {
    currency: "eur",
    symbol: "€",
  },
  {
    currency: "cad",
    symbol: "$",
  },
  {
    currency: "chf",
    symbol: "fr",
  },
  {
    currency: "jpy",
    symbol: "¥",
  },
  {
    currency: "sek",
    symbol: "kr",
  },
  {
    currency: "dkk",
    symbol: "kr",
  },
  {
    currency: "pln",
    symbol: "zł",
  },
  {
    currency: "aud",
    symbol: "$",
  },
  {
    currency: "nzd",
    symbol: "$",
  },
  {
    currency: "hkd",
    symbol: "$",
  },
  {
    currency: "nok",
    symbol: "kr",
  },
  {
    currency: "bgn",
    symbol: "лв",
  },
  {
    currency: "brl",
    symbol: "R$",
  },
  {
    currency: "czk",
    symbol: "Kč",
  },
  {
    currency: "ils",
    symbol: "₪",
  },
  {
    currency: "huf",
    symbol: "Ft",
  },
  {
    currency: "inr",
    symbol: "₹",
  },
  {
    currency: "mxn",
    symbol: "$",
  },
  {
    currency: "ron",
    symbol: "lei",
  },
  {
    currency: "sgd",
    symbol: "$",
  },
  {
    currency: "clp",
    symbol: "$",
  },
  {
    currency: "try",
    symbol: "₺",
  },
  {
    currency: "zar",
    symbol: "R",
  },
  {
    currency: "isk",
    symbol: "kr",
  },
  {
    currency: "cny",
    symbol: "¥",
  },
  {
    currency: "uah",
    symbol: "₴",
  },
  {
    currency: "twd",
    symbol: "NT$",
  },
];

export default currencies;
